import { ConfigState } from 'HealthAdvisorFrontendApp'

export const WEBSITE_NAME = 'Local Physio' // used in routes
export const PRACTITIONERS_URL = 'physiotherapists' // used in routes
export const PRACTITIONERS_LABEL = 'Physiotherapists' // used in routes
export const PRACTITIONER_LABEL = 'Physiotherapist' // used in routes

export const ARTICLES_PER_PAGE = 12
export const MARKERS_PER_SEARCH = 10
export const REVIEW_PER_PAGE = 10 // used in pratctioner and clinic page
export const CLINICS_PER_PAGE = 16 // for clinic index
export const PRACTITIONERS_PER_PAGE = 16 // for clinic index
export const API_PAGE_HOME_ID = 'homepage'
export const API_PAGE_ARTICLES_ID = 'articles'
export const API_PAGE_LOGIN = 'login-page'
export const API_PAGE_SIGNUP = 'signup-page'
export const API_SIGNUP_TERMS = 'sign-up-terms'
export const SOCIAL_NETWORKS: ConfigState['socialNetworks'] = [
  {
    title: 'Like us on Facebook',
    type: 'facebook',
    url: 'https://www.facebook.com/Local.Physio'
  },
  {
    title: 'Follow us on Twitter',
    type: 'twitter',
    url: 'https://twitter.com/home'
  }
]
export const DEFAULT_COORDS = '51.5073504,-0.12775825'

export const DEFAULT_SEO_DATA = {
  title: 'Local Physio',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'Find a Physiotherapist. Anywhere. Anytime'
    }
  ]
}

export const DEFAULT_SITE_URL = 'https://www.local-physio.co.uk'

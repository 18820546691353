


















import Vue from 'vue'

export default Vue.extend({
  name: 'LogoBrand',
  functional: true,
  props: {
    alternate: {
      type: Boolean,
      default: false
    },
    forceWhiteLogoInMobile: {
      type: Boolean,
      default: false
    },
    extraCss: {
      type: String,
      default: undefined
    }
  }
})

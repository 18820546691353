import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ed483eee = () => interopDefault(import('../pages/holding-page.vue' /* webpackChunkName: "pages/holding-page" */))
const _66a6712c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _ac23e56a = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _5cd45dca = () => interopDefault(import('../pages/signup/add-clinics/index.vue' /* webpackChunkName: "pages/signup/add-clinics/index" */))
const _24451cd6 = () => interopDefault(import('../pages/signup/add-payment/index.vue' /* webpackChunkName: "pages/signup/add-payment/index" */))
const _94f5a720 = () => interopDefault(import('../pages/signup/onboardingSettings.ts' /* webpackChunkName: "pages/signup/onboardingSettings" */))
const _f13178fc = () => interopDefault(import('../pages/signup/payment/index.vue' /* webpackChunkName: "pages/signup/payment/index" */))
const _3a97af86 = () => interopDefault(import('../pages/signup/register/index.vue' /* webpackChunkName: "pages/signup/register/index" */))
const _1ed8b93f = () => interopDefault(import('../pages/signup/success/index.vue' /* webpackChunkName: "pages/signup/success/index" */))
const _fd32b038 = () => interopDefault(import('../pages/signup/add-clinics/validateForm.ts' /* webpackChunkName: "pages/signup/add-clinics/validateForm" */))
const _08bb9d86 = () => interopDefault(import('../pages/signup/register/validateForm.ts' /* webpackChunkName: "pages/signup/register/validateForm" */))
const _02fdba52 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/holding-page",
    component: _ed483eee,
    name: "holding-page___en"
  }, {
    path: "/login",
    component: _66a6712c,
    name: "login___en"
  }, {
    path: "/signup",
    component: _ac23e56a,
    name: "signup___en"
  }, {
    path: "/signup/add-clinics",
    component: _5cd45dca,
    name: "signup-add-clinics___en"
  }, {
    path: "/signup/add-payment",
    component: _24451cd6,
    name: "signup-add-payment___en"
  }, {
    path: "/signup/onboardingSettings",
    component: _94f5a720,
    name: "signup-onboardingSettings___en"
  }, {
    path: "/signup/payment",
    component: _f13178fc,
    name: "signup-payment___en"
  }, {
    path: "/signup/register",
    component: _3a97af86,
    name: "signup-register___en"
  }, {
    path: "/signup/success",
    component: _1ed8b93f,
    name: "signup-success___en"
  }, {
    path: "/signup/add-clinics/validateForm",
    component: _fd32b038,
    name: "signup-add-clinics-validateForm___en"
  }, {
    path: "/signup/register/validateForm",
    component: _08bb9d86,
    name: "signup-register-validateForm___en"
  }, {
    path: "/",
    component: _02fdba52,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
